import React from 'react';
import { Table } from 'reactstrap';
import './price-list.css';

const Prices = ({ housings }) => {
    const list = housings.map(housing => {
        const prices = housing.prices.map(el => {
            return (
                <tr className="tableBody" key={el.name}>
                    <td>{el.name}</td>
                    <td>{el.price}</td>
                    <td>{el.weekendPrice}</td>
                </tr>
            )
        })
        return (
            <tbody key={housing.housing}>
                <tr className="tableHead">
                    <td>Корпус №{housing.housing}</td>
                    <td>Цена в будни</td>
                    <td>Цена в выходные</td>
                </tr>
                {prices}
                <tr>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        )
    })

    return (
        <Table className="table">
            {list}
        </Table>
    )
}
export default Prices;
