import React from 'react';
import { AboutPage, PricesPage, RoomsPage, ContactsPage, MainPage, SalesPage } from '../pages';
import { Container } from 'reactstrap';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from '../header';
import './app.css';

const App = () => {
    return (
        <Router>
            <div className="app" style={{
                backgroundImage: 'url(images/misc/ozerelov.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed'
            }}>
                <Header />
                <Container>
                    <Route path='/' exact component={MainPage} />
                    <Route path='/prices' component={PricesPage} />
                    <Route path='/about' component={AboutPage} />
                    <Route path='/rooms' component={RoomsPage} />
                    <Route path='/contacts' component={ContactsPage} />
                    <Route path='/sales' component={SalesPage} />
                </Container>
            </div>
        </Router>
    )
}

export default App;
