import React from 'react';
import Pictures from '../pictures-slider';
import { ListGroupItem } from 'reactstrap';
import './rooms-list-item.css';

const Room = ({ obj }) => {

    if (!obj) { return null };
    const { number, name, housing, category, price, weekendPrice, text, pictures } = obj;
    const srcs = [];
    pictures.forEach((picture) => {
        srcs.push(`images/${category}/${housing}/${number}/${picture}`);
    });

    const id = category + housing + number;

    return (
        <ListGroupItem key={id}>
            <div className="room" id={id}>
                <div className="name">{name}, {housing} корпус</div>
                <div className="price">Цена в будни {price + 'р.'}</div>
                <div className="weekendPrice">Цена в выходные {weekendPrice + 'р.'}</div>
                <div className="pictures">
                    <Pictures pictures={srcs} />
                </div>
                <div className="text">{text}</div>
            </div>
        </ListGroupItem>
    )

}
export default Room;
