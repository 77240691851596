import React from 'react';

const ContactsPage = () => {
    return (
        <div className="about">
            <h2>Контактная информация</h2>
            <div className="contactCard">
                <h3>Озеро Еловое</h3>
                <address>
                    Чебаркуль<br />
                456400, г.Чебаркуль<br />
                    <strong>Телефон: +79525129738</strong><br />
                    <strong>Электронная почта: chestem@mail.ru</strong>
                </address>
            </div>
            <iframe width="100%" height="600" frameBorder="0" allowFullScreen title="GMap" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d292585.774317383!2d60.601419631063!3d55.04625828093534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x43c592cb104a3a8d%3A0xef224a2a6d1711bf!2z0KfQtdC70Y_QsdC40L3RgdC6LCDQp9C10LvRj9Cx0LjQvdGB0LrQsNGPINC-0LHQuy4!3m2!1d55.1644419!2d61.4368432!4m5!1s0x43c56bef9e487133%3A0x18901042074edcb8!2z0JrQsNC80LXQvdC90YvQuSDQkdC10YDQtdCzLCDQp9C10LHQsNGA0LrRg9C70YwsINCn0LXQu9GP0LHQuNC90YHQutCw0Y8g0L7QsdC7LiwgNDU2NDQx!3m2!1d55.005368399999995!2d60.3180542!5e0!3m2!1sru!2sru!4v1522406770974"></iframe>
            <div className="bigInfo">
                <h2>Как добраться</h2>
                <h3>На личном автомобиле:</h3>
                <ul>
                    <li>Выезд через пост ГИБДД (район АМЗ), далее следуем 50 км по федеральной трассе М-5 Москва-Уфа проезжаем через п. Тимирязевский, с трассы поворот направо на п. Мисяш, проезд через железнодорожные пути и поселок Мисяш по главной дороге до АЗС «Лукойл», на АЗС «Лукойл» поворот направо по указателю на Миасс до поворота налево с указателем «База отдыха «Каменный берег».</li>
                    <li>Выезд через пост ГИБДД (район С/З), далее по главной дороге следуем через населенные пункты Кременкуль, Бол. Харлуши, Кулуево, Яраткулова, Верхние Караси, Непряхино, мимо танкодрома по главной дороге по указателю на Чебаркуль до АЗС «Лукойл», на АЗС «Лукойл» поворот направо по указателю на Миасс до поворота налево с указателем «База отдыха «Каменный берег».</li>
                </ul>
                <h3>На общественном транспорте:</h3>
                <ul>
                    <li>Электропоездом или автобусом с автовокзала до г. Чебаркуль. Далее на такси до «База отдыха «Каменный берег»(ориентировочная стоимость 100 рублей за машину)</li>
                </ul>
            </div>
        </div>
    )
}
export default ContactsPage;
