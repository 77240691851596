import React from 'react';
import { Jumbotron, Button, NavLink, Col, Row } from 'reactstrap';

const MainPage = () => {
    return (
        <>
            <div className="infoCard" style={{ backgroundImage: 'url(images/misc/bg.jpg)' }}>
                <h1>База отдыха Каменный берег</h1>
                <h2>Телефон: +7(952)512-97-38</h2>
            </div>
            <Row className="buttons">
                <Col sm="4">
                    <Button outline color="secondary">
                        <NavLink href='/rooms'>
                            <span>Номера</span><br />
                            <img className="btnimg" src="images/Main/rooms.jpg" alt="Номера" />
                        </NavLink>
                    </Button>
                </Col>
                <Col sm="4">
                    <Button outline color="secondary">
                        <NavLink href='/prices'>
                            <span>Цены</span><br />
                            <img className="btnimg" src="images/Main/Snimok1.jpg" alt="Цены" />
                        </NavLink>
                    </Button>
                </Col>
                <Col sm="4">
                    <Button outline color="secondary">
                        <NavLink href='/about'>
                            <span>Услуги</span><br />
                            <img className="btnimg" src="images/Main/usl.jpg" alt="Услуги" />
                        </NavLink>
                    </Button>
                </Col>
            </Row>
            <Jumbotron className="bigInfo">
                <h2>Въезд на территорию базы отдыха с автодороги Чебаркуль-Миасс</h2>
                <iframe width="100%" height="400" frameBorder="0" title="entry" allowFullScreen src="https://www.google.com/maps/embed?pb=!4v1522939948322!6m8!1m7!1s1XHhXIeNHsPwjn0FC5ZC9Q!2m2!1d55.00582645568542!2d60.31831196578005!3f241.28!4f-11.25!5f0.7820865974627469"></iframe>
            </Jumbotron>
        </>
    )

}
export default MainPage;
