const PricesDB = [{
    "housing": 1,
    "prices": [{
        "housing": "1",
        "name": "Двухместные Апартаменты",
        "price": "4000",
        "weekendPrice": "4500"
    }, {
        "housing": "1",
        "name": "Четырехместные Апартаменты",
        "price": "6000",
        "weekendPrice": "6500"
    }]
}, {
    "housing": 2,
    "prices": [{
        "housing": "2",
        "name": "Двухместный Эконом",
        "price": "1000",
        "weekendPrice": "1000"
    }]
}, {
    "housing": 3,
    "prices": [{
        "housing": "3",
        "name": "Двухместный Эконом",
        "price": "900",
        "weekendPrice": "900"
    }]
}, {
    "housing": 4,
    "prices": [{
        "housing": "4",
        "name": "Двухместный Стандарт",
        "price": "2000",
        "weekendPrice": "2300"
    }, {
        "housing": "4",
        "name": "Трехместный Стандарт",
        "price": "3000",
        "weekendPrice": "3300"
    }, {
        "housing": "4",
        "name": "Четырехместный Стандарт",
        "price": "3600",
        "weekendPrice": "3900"
    }]
}, {
    "housing": 5,
    "prices": [{
        "housing": "5",
        "name": "Двухместный Стандарт",
        "price": "1900",
        "weekendPrice": "2200"
    }, {
        "housing": "5",
        "name": "Трехместный Стандарт",
        "price": "2800/3000",
        "weekendPrice": "3100/3300"
    }, {
        "housing": "5",
        "name": "Четырехместный Стандарт",
        "price": "3600",
        "weekendPrice": "3900"
    }]
}, {
    "housing": 6,
    "prices": [{
        "housing": "6",
        "name": "Двухместные Апартаменты",
        "price": "4000",
        "weekendPrice": "4500"
    }, {
        "housing": "6",
        "name": "Трехместные Апартаменты",
        "price": "5000",
        "weekendPrice": "5500"
    }, {
        "housing": "6",
        "name": "Четырехместные Апартаменты",
        "price": "6000",
        "weekendPrice": "6500"
    }, {
        "housing": "6",
        "name": "Двухместный Стандарт",
        "price": "1800",
        "weekendPrice": "2100"
    }, {
        "housing": "6",
        "name": "Трехместный Стандарт",
        "price": "2600",
        "weekendPrice": "2900"
    }, {
        "housing": "6",
        "name": "Четырехместный Стандарт",
        "price": "3400",
        "weekendPrice": "3700"
    }]
}, {
    "housing": 7,
    "prices": [{
        "housing": "7",
        "name": "Трехместный Эконом",
        "price": "1800",
        "weekendPrice": "1800"
    }, {
        "housing": "7",
        "name": "Четырехместный Эконом",
        "price": "2400",
        "weekendPrice": "2400"
    }]
}]
export default PricesDB;
