import React from 'react';
import Room from '../rooms-list-item';
import { ListGroup } from 'reactstrap';
import './rooms-list.css';

const List = ({ rooms }) => {
    const list = rooms.map(room => {
        const id = room.category + room.housing + room.number;
        return (
            <Room obj={room} key={id} />
        )
    });
    const err = (
        <div className="errormsg">К сожалению, номера по такому запросу не найдены, попробуйте изменить условия поиска</div>
    );
    const res = (rooms.length === 0) ? err : list;
    return (
        <ListGroup className="roomsList">
            {res}
        </ListGroup>
    )
}
export default List;
