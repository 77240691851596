import React from 'react';
import PricesDB from '../db/getPrices';
import Prices from '../price-list';

const PricesPage = () => {
    const data = PricesDB;
    return (
        <div className="about">
            <h2>Прайс</h2>
            <Prices housings={data} />
        </div>
    )
}
export default PricesPage;
