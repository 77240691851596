import React from 'react';
import Pictures from '../pictures-slider';
import { Jumbotron } from 'reactstrap';

const AboutPage = () => {
    let pictures = ['playground.jpg', 'mangal2.jpg', 's7.jpg', 'Snimok1.jpg', 'Snimok2.jpg', 'Snimok3.jpg'];
    pictures = pictures.map(pic => {
        return pic = 'images/About/' + pic;
    });

    return (
        <Jumbotron>
            <div className="about bigInfo">
                <h2>На территории базы размещены:</h2>
                <ul className="aboutList">
                    <li>6 кирпичных корпусов и 1 деревянный.</li>
                    <li>Кафе-столовая.</li>
                    <li>Детская игровая площадка.</li>
                    <li>Более 10 мангальных зон, расположенных в отдалении друг от друга. Мангальные зоны освещены и есть у каждого корпуса и у берега озера. Бесплатно для проживающих.</li>
                    <li>Размещение личного автотранспорта на парковке рядом с корпусом.</li>
                </ul>
                <p>Соседство с б/о «Уральские зори» позволит желающим разнообразить свой отдых активными видами развлечений.</p>
                <div className="aboutContainer">
                    <Pictures id="about" pictures={pictures} />
                </div>
            </div>
        </Jumbotron>

    )
}
export default AboutPage;
