const DB = [{
    "number": "2m",
    "name": "Двухместныe апартаменты",
    "housing": 1,
    "category": "Lux",
    "price": 4000,
    "weekendPrice": 4500,
    "text": "Номер с двухспальной кроватью. Душевая кабина, санузел. Кухня оборудована электроплитой, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["3.jpeg", "4.jpeg", "5.jpeg"]
}, {
    "number": "4m",
    "name": "Четырехместныe апартаменты",
    "housing": 1,
    "category": "Lux",
    "price": 6000,
    "weekendPrice": 6500,
    "text": "Двухкомнатные апартаменты с двумя двухспальными кроватями. Душевая кабина, санузел. Кухня оборудована электроплитой, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["6.jpeg", "7.jpeg", "8.jpeg", "9,jpeg"]
}, {
    "number": "2mb",
    "name": "Двухместныe апартаменты c балконом",
    "housing": 6,
    "category": "Lux",
    "price": 4000,
    "weekendPrice": 4500,
    "text": "Номер с двухспальной кроватью. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["10.jpeg", "11.jpeg", "12.jpeg", "13.jpeg", "DSC_0668.jpg"]
}, {
    "number": "2mm",
    "name": "Двухместныe апартаменты мансарда",
    "housing": 6,
    "category": "Lux",
    "price": 4000,
    "weekendPrice": 4500,
    "text": "Номер с двухспальной кроватью. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["15.jpeg", "16.jpeg", "17.jpeg", "18.jpeg"]
}, {
    "number": "2mms",
    "name": "Двухместныe апартаменты студия-мансарда",
    "housing": 6,
    "category": "Lux",
    "price": 4000,
    "weekendPrice": 4500,
    "text": "Номер с двухспальной кроватью. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["20.jpeg", "21.jpeg", "22.jpeg", "23.jpeg"]
}, {
    "number": "2ms",
    "name": "Студия",
    "housing": 6,
    "category": "Lux",
    "price": 3500,
    "weekendPrice": 4000,
    "text": "Номер с двумя односпальными кроватями. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой..",
    "pictures": ["25.jpeg", "26.jpeg"]
}, {
    "number": "3m",
    "name": "Трёхместныe апартаменты c балконом",
    "housing": 6,
    "category": "Lux",
    "price": 5000,
    "weekendPrice": 5500,
    "text": "Номер с двухспальной и односпальной кроватями. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["30.jpeg", "31.jpeg", "32.jpeg", "33.jpeg", "34.jpeg"]
}, {
    "number": "4mb",
    "name": "Четырёхместныe апартаменты мансарда",
    "housing": 6,
    "category": "Lux",
    "price": 6000,
    "weekendPrice": 6500,
    "text": "Двухкомнатные апартаменты с двуспальной и двумя односпальными кроватями. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["DSC_0685.jpg", "DSC_0678.jpg", "DSC_0679.jpg", "DSC_0675.jpg", "DSC_0689.jpg"]
}, {
    "number": "4mm",
    "name": "Четырёхместныe апартаменты c балконом",
    "housing": 6,
    "category": "Lux",
    "price": 6000,
    "weekendPrice": 6500,
    "text": "Двухкомнатные апартаменты с двуспальной и двумя односпальными кроватями. Душевая кабина, санузел. Кухня оборудована СВЧ-печью, холодильником, чайником, столовыми приборами и посудой.",
    "pictures": ["DSC_0700.jpg", "DSC_0702.jpg", "DSC_0705.jpg", "DSC_0722.jpg", "DSC_0726.jpg", "DSC_0732.jpg", "DSC_0736.jpg"]
}, {
    "number": "2m",
    "name": "Двухместный стандарт",
    "housing": 4,
    "category": "Standart",
    "price": 2000,
    "weekendPrice": 2300,
    "text": "Номер с двумя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["41.jpeg", "DSC_0809.jpg", "42.jpeg", "43.jpeg"]
}, {
    "number": "3m",
    "name": "Трехместный стандарт",
    "housing": 4,
    "category": "Standart",
    "price": 3000,
    "weekendPrice": 3300,
    "text": "Номер с тремя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["50.jpeg", "DSC_0807.jpg", "DSC_0805.jpg", "51.jpeg", "52.jpeg"]
}, {
    "number": "4m",
    "name": "Четырехместный стандарт",
    "housing": 4,
    "category": "Standart",
    "price": 3600,
    "weekendPrice": 3900,
    "text": "Номер с четырьмя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0814.jpg", "61.jpeg", "62.jpeg"]
}, {
    "number": "2m",
    "name": "Двухместный стандарт",
    "housing": 5,
    "category": "Standart",
    "price": 1900,
    "weekendPrice": 2200,
    "text": "Номер с двумя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0816.jpg", "DSC_0820.jpg", "DSC_0828.jpg", "DSC_0829.jpg"]
}, {
    "number": "3m",
    "name": "Трехместный стандарт",
    "housing": 5,
    "category": "Standart",
    "price": 2800,
    "weekendPrice": 3100,
    "text": "Номер с тремя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0898.jpg", "DSC_0897.jpg"]
}, {
    "number": "3m2",
    "name": "Трехместный стандарт двухкомнатный",
    "housing": 5,
    "category": "Standart",
    "price": 3000,
    "weekendPrice": 3300,
    "text": "Номер с тремя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0830.jpg", "DSC_0831.jpg", "DSC_0833.jpg", "DSC_0834.jpg", "DSC_0828.jpg", "DSC_0829.jpg"]
}, {
    "number": "4m",
    "name": "Четырехместный стандарт",
    "housing": 5,
    "category": "Standart",
    "price": 3600,
    "weekendPrice": 3900,
    "text": "Двухкомнатный номер с четырьмя односпальными кроватями. В номере имеется холодильник,чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0894.jpg", "DSC_0895.jpg", "DSC_0897.jpg"]
}, {
    "number": "2m",
    "name": "Двухместный стандарт с балконом",
    "housing": 6,
    "category": "Standart",
    "price": 1800,
    "weekendPrice": 2100,
    "text": "Номер категории Стандарт с двумя односпальными кроватями.В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0755.jpg", "DSC_0669.jpg"]
}, {
    "number": "3m",
    "name": "Трехместный стандарт с балконом",
    "housing": 6,
    "category": "Standart",
    "price": 2600,
    "weekendPrice": 2900,
    "text": "Номер категории Стандарт с тремя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0761.jpg", "DSC_0669.jpg"]
}, {
    "number": "4m",
    "name": "Четырехместный стандарт с балконом",
    "housing": 6,
    "category": "Standart",
    "price": 3400,
    "weekendPrice": 3700,
    "text": "Номер категории Стандарт с четырьмя односпальными кроватями. В номере имеется холодильник, чайник, столовые приборы и посуда.",
    "pictures": ["DSC_0748.jpg", "DSC_0751.jpg", "DSC_0754.jpg"]
}, {
    "number": "2m",
    "name": "Номер эконом варианта",
    "housing": 2,
    "category": "Econom",
    "price": 1000,
    "weekendPrice": 1000,
    "text": "В комнате 2 односпальные кровати. На этаже на три номера холодильник и умывальник с горячей водой. Туалет общественный канализованный (чаша Генуя) на улице.",
    "pictures": ["DSC_0857.jpg", "DSC_0865.jpg"]
}, {
    "number": "2m",
    "name": "Номер эконом варианта",
    "housing": 3,
    "category": "Econom",
    "price": 900,
    "weekendPrice": 900,
    "text": "В номере 2 односпальные кровати. Туалет общественный канализованный (чаша Генуя) на улице.",
    "pictures": ["DSC_0874.jpg", "DSC_0871.jpg"]
}, {
    "number": "3m",
    "name": "Трехместный номер эконом варианта",
    "housing": 7,
    "category": "Econom",
    "price": 1800,
    "weekendPrice": 1800,
    "text": "Финский домик. В номере есть холодильник, чайник, посуда и столовые приборы. Туалет общественный канализованный (чаша Генуя) на улице. Умывальники рядом с домиком на улице.",
    "pictures": ["DSC_0887.jpg", "DSC_0883.jpg"]
}, {
    "number": "4m",
    "name": "Четырехместный номер эконом варианта",
    "housing": 7,
    "category": "Econom",
    "price": 2400,
    "weekendPrice": 2400,
    "text": "Финский домик. В номере есть холодильник, чайник, посуда и столовые приборы. Туалет общественный канализованный (чаша Генуя) на улице. Умывальники рядом с домиком на улице.",
    "pictures": ["DSC_0878.jpg", "DSC_0875.jpg", "1.jpeg", "2.jpeg"]
}];
export default DB;
