import React, { useState } from 'react';
import './header.css';
import {
    Navbar,
    NavbarToggler,
    NavLink,
    NavItem,
    NavbarBrand,
    Collapse,
    Nav,
    Container
} from 'reactstrap';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar light expand="md">
            <Container>
                <NavbarBrand href='/'>
                    Каменный Берег
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <NavLink href='/rooms'>Проживание</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/about'>Услуги</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/prices'>Цены</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/contacts'>Контакты</NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink href='/sales'>Акции</NavLink>
                        </NavItem> */}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
