import React, { useState } from 'react';
import DB from '../db/getDb';
import List from '../rooms-list';
//import './pages.css';
import { Form, FormGroup, Label, Input, Collapse, Button } from 'reactstrap';


const RoomsPage = () => {
    const data = DB;
    data.forEach(room => {
        room.beds = +room.number.charAt(0);
    })
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [dataState, setDataState] = useState(data);
    const [bedsState, setBedsState] = useState("");
    const [categoryState, setCategoryState] = useState("");
    const [minPriceState, setMinPriceState] = useState("");
    const [maxPriceState, setMaxPriceState] = useState("");
    const [housingState, setHousingState] = useState("");
    const submit = () => {
        let showData = data;
        if ("" !== categoryState) {
            showData = showData.filter(room => room.category === categoryState);
        }
        if ("" !== housingState) {
            showData = showData.filter(room => room.housing === +housingState);
        }
        if ("" !== minPriceState) {
            showData = showData.filter(room => room.price >= +minPriceState);
        }
        if ("" !== maxPriceState) {
            showData = showData.filter(room => room.price <= +maxPriceState);
        }
        if ("" !== bedsState) {
            showData = showData.filter(room => room.beds === +bedsState);
        }
        setDataState(showData);
    }
    return (
        <>
            <div className="formwrapper">
                <Form id="form" className="form" onSubmit={(e) => { e.preventDefault(); submit() }}>
                    <Button className="findbtn" color="secondary" outline onClick={(e) => { e.preventDefault(); toggle() }} style={{ marginBottom: '1rem' }}>Искать подходящие номера</Button>
                    <Collapse isOpen={isOpen}>
                        <FormGroup>
                            <Label htmlFor="beds">Количество спальных мест</Label>
                            <Input type="select" className="input" id="beds" name="beds" onChange={(e) => { setBedsState(e.target.value) }}>
                                <option value=""></option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <span>Цена </span>
                            <Label htmlFor="minPrice">От:</Label>
                            <Input className="input" type="number" id="minPrice" name="minPrice" onChange={(e) => { setMinPriceState(e.target.value) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="maxPrice">До:</Label>
                            <Input className="input" type="number" id="maxPrice" name="maxPrice" onChange={(e) => { setMaxPriceState(e.target.value) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="category">Категория</Label>
                            <Input type="select" className="input" id="category" name="category" onChange={(e) => { setCategoryState(e.target.value) }}>
                                <option value=""></option>
                                <option value="Econom">Эконом</option>
                                <option value="Standart">Стандарт</option>
                                <option value="Lux">Люкс</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="housing">Корпус</Label>
                            <Input type="select" className="input" id="housing" name="housing" onChange={(e) => { setHousingState(e.target.value) }}>
                                <option value=""></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </Input>
                        </FormGroup>
                        <Input id="submit" className="btn input" type="submit" value="Поиск" />
                    </Collapse>
                </Form>
            </div>
            <List rooms={dataState} />
        </>

    )
}
export default RoomsPage;
