import React from 'react';
import { Jumbotron, Table } from 'reactstrap';

const SalesPage = () => {
    return (
        <Jumbotron>
            <div className="about bigInfo">
                <h2>Акция до 30 июня! Скидка 30% на проживание от 6 дней</h2>
                <Table className="table">
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="tableHead">
                        <td>Апартаменты</td>
                        <td>6 дней/5 ночей</td>
                        <td>8 дней/7 ночей</td>
                    </tr>
                    <tr>
                        <td>для 2-х гостей</td>
                        <td>14 000</td>
                        <td>20 000</td>
                    </tr>
                    <tr>
                        <td>для 3-х гостей</td>
                        <td>17 500</td>
                        <td>25 000</td>
                    </tr>
                    <tr>
                        <td>для 4-х гостей</td>
                        <td>21 000</td>
                        <td>30 000</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="tableHead">
                        <td>Стандарт корпус 4</td>
                        <td>6 дней/5 ночей</td>
                        <td>8 дней/7 ночей</td>
                    </tr>
                    <tr>
                        <td>для 2-х гостей</td>
                        <td>7 000</td>
                        <td>10 000</td>
                    </tr>
                    <tr>
                        <td>для 3-х гостей</td>
                        <td>10 500</td>
                        <td>15 000</td>
                    </tr>
                    <tr>
                        <td>для 4-х гостей</td>
                        <td>14 000</td>
                        <td>15 000</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="tableHead">
                        <td>Стандарт корпус 5</td>
                        <td>6 дней/5 ночей</td>
                        <td>8 дней/7 ночей</td>
                    </tr>
                    <tr>
                        <td>для 2-х гостей</td>
                        <td>6 500</td>
                        <td>9 000</td>
                    </tr>
                    <tr>
                        <td>для 3-х гостей</td>
                        <td>9 500</td>
                        <td>13 500</td>
                    </tr>
                    <tr>
                        <td>для 4-х гостей</td>
                        <td>13 000</td>
                        <td>16 000</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="tableHead">
                        <td>Стандарт корпус 6</td>
                        <td>6 дней/5 ночей</td>
                        <td>8 дней/7 ночей</td>
                    </tr>
                    <tr>
                        <td>для 2-х гостей</td>
                        <td>6 000</td>
                        <td>8 000</td>
                    </tr>
                    <tr>
                        <td>для 3-х гостей</td>
                        <td>8 500</td>
                        <td>12 000</td>
                    </tr>
                    <tr>
                        <td>для 4-х гостей</td>
                        <td>11 500</td>
                        <td>16 000</td>
                    </tr>
                </Table>
            </div>
            <div style={{ height: 500 }}></div>
        </Jumbotron>

    )
}
export default SalesPage;
